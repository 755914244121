
import { mapGetters } from 'vuex'
import { PropOptions } from 'vue'
import { Container, Row, Column } from '~/components/grid'
import {
  HlrInput,
  AlertBlock,
  SwitchCountryButton,
} from '~/components/molecules'

import { PageData } from '~/models/base/Page'
import BoxList from '~/components/organisms/category-list/box-list.vue'
import { NO_RTR_COUNTRIES } from '~/utils/constants/category-brand-legal'

import {
  CategoryBanner,
  UspsBlock,
  FreeForm,
  BrandList,
} from '~/components/organisms'
import { hashBrands } from '~/utils/hashBrands'
import { PRE_FETCH_PARAM, NO_HLR_COUNTRIES } from '~/utils/constants/hlr'
import { TAXON_PRODUCTS_LIMIT } from '~/pages/category-brand-legal.vue'
import { CdpContainer } from '~/components/renderers'
export default {
  components: {
    CdpContainer,
    UspsBlock,
    BoxList,
    BrandList,
    Container,
    FreeForm,
    HlrInput,
    CategoryBanner,
    Row,
    Column,
    AlertBlock,
    SwitchCountryButton,
  },
  props: {
    pageData: {
      type: Object,
      required: true,
    } as PropOptions<PageData>,
    countries: {
      type: Array,
      default: () => [],
    },
    refreshLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moreProductsFetched: false,
    }
  },
  computed: {
    ...mapGetters('context', [
      'country',
      'isGlobalMarketplace',
      'isGlobalCountry',
      'countries',
    ]),
    ...mapGetters('contentful', ['sharedMenu']),
    brands() {
      return this.pageData.sectionBrandList[0]?.brands
    },
    noBrands() {
      return !this.brands || this.brands.length === 0
    },
    isHLRAvailable() {
      // The list of countries without RTR products on the moment of this TEMPORARY fix
      // See OP-1306
      // Moved to constants file in OP-1088, HLR is used in carriers as well
      return (
        this.isMobileTopup &&
        !this.isGlobalCountry &&
        !NO_HLR_COUNTRIES.includes(this.country.code.toUpperCase()) &&
        !NO_RTR_COUNTRIES.includes(this.country.code.toUpperCase())
      )
    },
    isMobileTopup() {
      return this.$route.path.match(/mobile-top-up/)
    },
    categoryCards() {
      if (!this.sharedMenu) return []
      return this.sharedMenu.filter(
        menuItem => menuItem.slug !== this.pageData.slug
      )
    },
    switchTranslationKey() {
      return this.noBrands ? 'change_country_to' : 'to_be_used_in'
    },
    showViewAllButton() {
      return (
        !this.moreProductsFetched && this.brands.length >= TAXON_PRODUCTS_LIMIT
      )
    },
    preFetchHlr() {
      return this.$route.query[PRE_FETCH_PARAM] === 'true'
    },
  },
  mounted() {
    if (this.noBrands) this.$gtmEnhanced.trackEmptyCategoriesPage()
  },
  methods: {
    onHlrInput(phoneNumberInfo) {
      const slug = phoneNumberInfo?.product?.slug
      if (!slug) return
      this.$router.push({ path: slug })
    },
    refreshCategory() {
      this.moreProductsFetched = true
      this.$emit('refresh-category-page')
    },
    hash(brands): string {
      return hashBrands(brands)
    },
  },
}
